// import { AfterShowSignin } from './afterShowLogin'
import toDot from './dot'

export const initConfig = {
    "src": "pcw_wan",
    "primaryColor": "#fd6426",  // 主色
    "domainlist": ['360.cn', 'pay.360.cn'],
    /* 登录配置 */
    "signIn": {  
        "types": [ "normal", "mobile" ], //"mobile"
        "findPwdTip": "忘记密码",
        "signUpTip": "立即注册",
        "thirdPart": { "providers": [ "sina", "tencent", "weixin" ] },
        "normal": { "panelTitle": "账号登录" },
        "mobile": { "panelTitle": "手机登录" },
        "keepAliveTip": "自动登录",
        "hideKeepAlive": true,
        "panelCloseHandler": null,
        "captchaWidth" : null,
        "agreement": false
    },
    /* 注册配置 */
    "signUp": {
      "types": [ "username" ],
      // "types": [ 'mobile'],
      "hidePasswordAgain": false, // 显示输入重复密码
      "username": { "panelTitle": "立即注册" },
      "protocolText": "360游戏服务及许可协议",
      "protocolUrl": "//wan.360.cn/protocol.html",
      "privacyText": "360游戏隐私政策",
      "privacyUrl": "https://360game.360.cn/theme/privacy_dt.html",
      "hideUsername": false, // 手机号注册展示用户名
      "panelCloseHandler": null,
      "captchaWidth" : null 
    }  
}



const initEvents = (events)=> {

    const signupSuccCallback = (userInfo) => {
        
        if(userInfo){
            // 注册成功上报打点数据
            toDot({
                case_key: 'wanposter_reg',
                event_key: 'registered_events',
                uid: userInfo.qid,
                param: `[{ "key": "user_name","value": ${userInfo.userName} }]`
            })
        }

        setTimeout(()=>{
            if(events.signupSuccCallback){
                if(userInfo){
                    events.signupSuccCallback(userInfo)
                } else {
                    events.signupSuccCallback()
                }
            } else {
                window.location.reload()
            }
        }, 300)
    }

    return{
        // 缺省时弹出浮层显示，传入Element则在该Element中显示
        'wrapper': events.wrapper ? events.wrapper : null, 
        // 展示登录模块后执行
        'afterShowSignin': ()=>{
            if(events.afterShowSignin){
                events.afterShowSignin()
            } else {
                // 使用自定义注册模块
                // AfterShowSignin(signupSuccCallback)
                if(!$('.wanGame-custom-sign-up').length){
                    // $('.wanGame-custom-sign-up').remove()
                    $('body').off('click', '.wanGame-custom-sign-up')
                    $('.quc-form-tips').find('.quc-link-sign-up').hide();
                    $('.quc-form-tips').find('.sep').hide();
                    $('.quc-form-tips').append('<a href="javascript:;" class="wanGame-custom-sign-up quc-left">立即注册</a>')
                    $('body').on('click', '.wanGame-custom-sign-up', () => {
                    // $('.quc-form-tips').children('.wanGame-custom-sign-up').on('click', ()=>{
                        $('body .quc-panel-close').click()
                        QHPass.signUp(function(userInfo) {
                            signupSuccCallback(userInfo)
                        });
                    })
                }
                
            }
        },
        // 展示注册模块前执行
        'beforeShowSignup': ()=>{
            if(events.beforeShowSignup){
                events.beforeShowSignup()
            }
        },
        // 展示注册模块后执行
        'afterShowSignup': ()=>{
            if(events.afterShowSignup){
                events.afterShowSignup()
            }
        },
        // 登录成功回调函数
        'signinSuccCallback': (userInfo)=>{
            if(events.signinSuccCallback){
                events.signinSuccCallback(userInfo)
            } else {
                window.location.reload()
            }
        },
        // 注册成功回调函数
        'signupSuccCallback': signupSuccCallback,
        // 退出成功回调函数
        'signOutSuccCallback': ()=>{
            if(events.signOutSuccCallback){
                events.signOutSuccCallback()
            } else {
                window.location.reload()
            }
        }
    }
}

/* 拆分配置项 和 事件函数 */
const assiginObj = (target = {},sources= {}) => {
    let obj = target;
    if(typeof target != 'object' || typeof sources != 'object'){
        return sources; 
    }
    for(let key in sources){ 
        if(target.hasOwnProperty(key)){
            obj[key] = assiginObj(target[key],sources[key]);
        }
    }
    return obj;
}


export const conifg = (params) => {
    let _params = assiginObj(initConfig, params)
    let _events = initEvents(params)
    
    return {
        initConfig: _params,
        initEvents: _events
    }
} 
