import QpassSdk from './QpassSdk';

const INSTANCE_SDK = new QpassSdk();

/* 重大事件置灰 */
// function getDomain(pattern, domain) {
//     var re = pattern.replace(/\?/g, ".").replace(/#/g, "\\d").replace(/\*+/g, ".*");
//     var path = window.location.pathname;
//     return (new RegExp(`^${re}\$`, "i")).test(domain) && path == '/game';
// }
// var importStyle=function importStyle(b){
//     var a=document.createElement("style");
//     var c=document;c.getElementsByTagName("head")[0].appendChild(a);
//     if(a.styleSheet){
//         a.styleSheet.cssText=b
//     }else{
//         a.appendChild(c.createTextNode(b))
//     }
// };
// try{
//     var domain = window.location.host;
//     var isNotShow = false;
//     isNotShow = getDomain("*.h5.wan.360.cn", domain)
//     if(!isNotShow){
//         importStyle('html {  -webkit-filter: grayscale(100%);-moz-filter: grayscale(100%); -ms-filter: grayscale(100%);-o-filter: grayscale(100%);filter: grayscale(100%);filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);}');//调用 
//     }
// } catch (err) {
//     console.log(err)
// }


export default {
    Config:  (params) => INSTANCE_SDK.Config(params),
    SignIn: () => INSTANCE_SDK.SignIn(),
    SignUp: () => INSTANCE_SDK.SignUp(),
    SugnOut: () => INSTANCE_SDK.SignOut()
}

