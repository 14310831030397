//import React, { Component, Fragment } from 'react';
import { conifg, initConfig } from '~/config/index.jsx'
import { QHpassSignin } from '~/components/signIn/index.jsx'
import { QHpassSignUp } from '~/components/signUp/index.jsx'
import { QHpassSignOut } from '~/components/signOut/index.jsx'
import { QHpassEvents } from '~/components/signEvents/index.jsx'

import '@scss/qhpass.scss'

export default class QpassSdk {

  static _INSTANCE
  static _CONFIG_PARAMS
  
  static INSTANCE_SDK() {
    if (!this._INSTANCE) {
      this._INSTANCE = new QpassSdk();
    }
    return this._INSTANCE;
  }

  constructor() {

  }

  Config(params) {
    this._CONFIG_PARAMS = conifg(params)
    return this
  }

  init(callback) {
    QHPass.init(this._CONFIG_PARAMS.initConfig)
    QHpassEvents(this._CONFIG_PARAMS.initEvents)
  }

  SignIn() {
    this.init()
    QHpassSignin(this._CONFIG_PARAMS.initEvents)
  }

  SignUp() {
    this.init()
    QHpassSignUp(this._CONFIG_PARAMS.initEvents)
  }

  SignOut() {
    QHpassSignOut(this._CONFIG_PARAMS.initEvents);
  }

}
