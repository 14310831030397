// import { 
//     GetQueryString,
//     getDataTimeNow,
//     getMid,
//     encodeParams
// } from '../services/utils'

/**
 * params 
 * case_key
 * event_key
 * qid
 * param [{"key":"user_name","value":"花花世界"}]
 */

function toDot(params){

  if(!params.case_key && !params.event_key){
      return false;
  }

  /**
 * 获取地址栏参数
 */
function GetQueryString(name) {
  var  reg =  new  RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
  var  r = window.location.search.substr(1).match(reg); 
  if(r!=null)  return  unescape(r[2]);  return  null;
}

/**
* 获取当前日期时间
*/
function getDataTimeNow() {
  var nowstr = new Date();
  var datenow = nowstr.getFullYear() + "-"
           + ((nowstr.getMonth() + 1) < 10 ? "0" : "") + (nowstr.getMonth() + 1) + "-"
           + (nowstr.getDate() < 10 ? "0" : "") + nowstr.getDate()
           + " "
           + (nowstr.getHours() < 10 ? "0" : "") + nowstr.getHours() + ":"
           + (nowstr.getMinutes() < 10 ? "0" : "") + nowstr.getMinutes() + ":"
           + (nowstr.getSeconds() < 10 ? "0" : "") + nowstr.getSeconds();
  return datenow;
}

/**
* getmid
*/
function getMid() {
  if(external.GetMID && external.GetSID){
      var t = external.GetMID(external.GetSID(window));
      return t;
  } 
}   


function encodeParams(obj) {
  var result = [];
  for (var key in obj) {
      if (obj[key] == null) {
          continue;
      }
      result.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return result.join('&');
}

  window.__monitor_game_regimgs = {};
  const defaultParams = {
      case_key: '',
      event_key: '',
      log_name: 'custom_event',
      log_src: 'client',
      biz_plat: 'term',
      channel: GetQueryString('channel') ? GetQueryString('channel') : '',
      src: GetQueryString('src') ? GetQueryString('src') : '',
      mid: getMid() ? getMid() : '',
      time_str: getDataTimeNow(),
  }

  const sendLog = function(url) {
    var id = 'log_' + (+new Date);
    const img = window['__monitor_game_regimgs'][id] = new Image();

    img.onload = img.onerror = function() {
      if(window.__monitor_game_regimgs && window['__monitor_game_regimgs'][id]) {
        window['__monitor_game_regimgs'][id] = null;
        delete window["__monitor_game_regimgs"][id];
      }
    };
    img.src = url;
  }

  // 扩展参数
  const extendParams = function(params) {
    return $.extend(defaultParams, params)
    // return Object.assign(defaultParams, params);
  }

  // 根据打点参数信息
  const generateUrl = function (params) {
    const apiUrl = 'https://dd.mgame.360.cn/t/game/common';
    var encode_Params = encodeParams(params);
    // 加上时间戳，防止缓存
    encode_Params += '&t=' + (+ new Date());
    const linkChart = apiUrl.indexOf('?') > -1 ? '&' : '?';
    const url = `${apiUrl}${linkChart}${encode_Params}`;
    return url;
  }
  
  const extendedParams = extendParams(params);
  const url = generateUrl(extendedParams);
  sendLog(url);
}

export default toDot;