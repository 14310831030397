export function QHpassEvents(events) {
    /* after signIn */
    QHPass.events.on('afterShow.signIn', ()=>{
        events.afterShowSignin()
    });
    
    /* after signUp */
    QHPass.events.one('afterShow.signUp', ()=>{
        events.afterShowSignup()
    });

    /* before signUp */
    QHPass.events.one('beforeShow.signUp', ()=>{
        events.beforeShowSignup()
    });

    /* changeType.signIn */
    QHPass.events.on('changeType.signIn', (e, type)=>{
        if(type === 'mobile') {
            events.afterShowSignin()
        }
    });

    
}

